/* ----------  Google Helper  ---------- */

const GoogleHelper = {
	getZipCode: place => {
		const pattern = /\d+/g;

		const data = _.filter(place.address_components, address => {
			const { types } = address;
			if(_.indexOf(types, 'postal_code') >= 0) return address;
		});

		if(data.length) {
			let zipCode = data[0].long_name;
			zipCode = zipCode.match(pattern);
			zipCode = parseInt(zipCode);

			return zipCode;
		}

		return false;
	}
}
