/*----------  Get Form Data  ----------*/

const getFormData = form => _.mapValues(_.keyBy($(form).serializeArray(), 'name'), 'value');

/* ----------  Get Multi Checked  ---------- */

const getMultiChecked = (form, name) => {
	const inputs = form.querySelectorAll(`[name=${ name }]`);
	const names = [];

	_.map(inputs, input => {
		if(input.checked) names.push(input.value);
	});

	return names;
}

/* ----------  Get Data By Group  ---------- */

const getDataByGroup = (form, group, opts) => {
	const options = {
		parent: '',
		array: false,
		keyBy: 'name',
		subGroup: false,
		groupBy: 'group',
		toggleValueAsBoolean: true,

		...opts
	}

	const { groupBy, keyBy, array, subGroup, parent, toggleValueAsBoolean } = options;

	let inputs = form.querySelectorAll(`[data-${ groupBy }=${ group }]`);
	if(subGroup && !parent) throw new Error('Must specify parent for subgroups.');
	if(subGroup) inputs = _.filter(inputs, input => input.getAttribute('data-group') === parent);

	if(array) {
		return _.map(inputs, input => input.value);
	} else {
		let data = {};

		_.map(inputs, input => {
			const { type, value } = input;
			const name = input.getAttribute(`data-${ keyBy }`) || input.name;
			const isNotSubGroup = _.isEmpty(input.getAttribute('data-sub-group'));

			if(isNotSubGroup) {
				if((type === 'checkbox' || type === 'radio') && toggleValueAsBoolean) {
					data[name] = input.checked;
				} else {
					data[name] = value;
				}
			}
		});

		return data;
	}
}

/* ----------  UUID  ---------- */

const UUID = {
	v4: () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}
}

/* ----------  Update Params  ---------- */

const UpdateURLParameter = (url, param, paramVal) => {
	let temp = '';
	let newAdditionalURL = '';
    let tempArray = url.split('?');

    const baseURL = tempArray[0];
    const additionalURL = tempArray[1];

    if (additionalURL) {
        tempArray = additionalURL.split('&');
        for (let i = 0; i < tempArray.length; i += 1){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = '&';
            }
        }
    }

    const rows_txt = `${ temp }${ param }=${ paramVal }`;
	const newUrl =  `${ baseURL }?${ newAdditionalURL }${ rows_txt }`;

	return newUrl;
}
