/* ----------  Toggles  ---------- */

const Toggle = {
	init: () => {
		const $input = $('[data-icheck]');

		if($input.length && typeof iCheck !== undefined) {
			$input.iCheck({
				checkboxClass: 'icheckbox_flat-blue',
				radioClass: 'iradio_flat-blue'
			});
		}
	}
}

/* ----------  Select 2  ---------- */

const Select = {
	init: () => {
		const selects = document.querySelectorAll('[data-select]');;

		if(selects.length && typeof select2 !== undefined) {
			_.map(selects, select => {
				$(select).css('width', '100%');
				Select.load(select);
			});
		}
	},

	load: select => {
		const options = select.getAttribute('data-select');
		const json = options ? JSON.parse(options) : '';

		$(select).select2({
			dropdownParent: $('#cntSelect2Dropdown'),
			...json
		});
	}
}

/* ----------  Tiny MCE  ---------- */

const TinyMce = {
	init: () => {
		const textareas = document.querySelectorAll('[data-tinymce]');

		if((typeof tinymce !== undefined) && textareas.length) {
			_.map(textareas, txt => {
				TinyMce.load(txt);
			});
		}
	},

	load: el => {
		tinymce.init({
			target: el,
			theme: 'modern',
			height: 300,
			plugins: [
				'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
				'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
				'save table contextmenu directionality emoticons template paste textcolor'
			],
			toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
		});
	}
}

/* ----------  Date Range Picker  ---------- */

const DateRange = {
	init: () => {
		const $inputs = $('[data-daterange]');

		if($inputs.length && typeof daterangepicker !== undefined) {
			_.map($inputs, input => {
				const $input = $(input);
				const sDate = $input.data('daterange-start') || moment();
				const eDate = $input.data('daterange-end') || moment();

				const startDate =  moment(sDate).format('MM/DD/YYYY');
				const endDate = moment(eDate).format('MM/DD/YYYY');

				$input.daterangepicker({ startDate, endDate });
			});
		}
	},

	getData: id => {
		const $input = $(id);
		return $input.data('daterangepicker');
	},

	getStartDate: (id, format = 'YYYY-MM-DD HH:mm:ss') => {
		const data = DateRange.getData(id);
		return data ? data.startDate.format(format) : null;
	},

	getEndDate: (id, format = 'YYYY-MM-DD HH:mm:ss') => {
		const data = DateRange.getData(id);
		return data ? data.endDate.format(format) : null;
	}
}

/* ----------  Date Picker  ---------- */

const JqDatepicker = {
	init: () => {
		const $inputs = $('[data-datepicker]');
		const inputNodeTypes = ['input'];

		if($inputs.length && typeof datepicker !== undefined) {
			_.map($inputs, input => {
				const $input = $(input);
				const nodeType = input.nodeName.toLowerCase();

				const format = input.getAttribute('data-format') || 'yyyy-mm-dd';
				const bindEls = document.querySelectorAll(input.getAttribute('data-bind'));

				var date = new Date();
				var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());

				$input.datepicker({
					autoclose: true,
					format,
				});

				$input.off('changeDate.dp').on('changeDate.dp', e => {
					const date = e.format(format);

					if(bindEls.length) {
						_.map(bindEls, el => el.value = date);
					}

					if(_.indexOf(inputNodeTypes, nodeType) < 0) {
						e.currentTarget.innerHTML = date;
					}
				});
			});
		}
	}
}

const JqDatemask = {
	init: () => {
		const $inputs = $('[data-datemask]');
		const inputNodeTypes = ['input'];

		if($inputs.length && typeof datepicker !== undefined) {
			_.map($inputs, input => {
				const $input = $(input);
				const nodeType = input.nodeName.toLowerCase();

				let format = input.getAttribute('data-format') || 'MM/DD/YYYY';
				format = 'MM/DD/YYYY';
				const bindEls = document.querySelectorAll(input.getAttribute('data-bind'));

				var date = new Date();
				var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());

				var momentMask = IMask(input, {
					mask: Date,
					pattern: format,
					lazy: false,
					min: new Date(1920, 0, 1),
					max: new Date(2030, 0, 1),

					format: function (date) {
						return moment(date).format(format);
					},
					parse: function (str) {
						return moment(str, format);
					},

					blocks: {
						YYYY: {
							mask: IMask.MaskedRange,
							from: 1920,
							to: 2030
						},
						MM: {
							mask: IMask.MaskedRange,
							from: 1,
							to: 12
						},
						DD: {
							mask: IMask.MaskedRange,
							from: 1,
							to: 31
						},
						HH: {
							mask: IMask.MaskedRange,
							from: 0,
							to: 23
						},
						mm: {
							mask: IMask.MaskedRange,
							from: 0,
							to: 59
						}
					}
				});
			});
		}
	}
}

/* ----------  Air Date Picker  ---------- */

const AirDatepicker = {
	init: () => {
		const inputs = document.querySelectorAll('[data-airdatepicker]');

		if(inputs.length && typeof datepicker !== undefined) {
			_.map(inputs, input => {
				const $input = $(input);

				const startDate = $input.data('airdatepicker') || $input.val();
				const date = startDate ? new Date(startDate) : null;

				$input.datepicker({
					language: 'en',
					startDate: date,
					autoClose: true,
					dateFormat: "mm/dd/yyyy",
				});

				const initialDate = moment(date).format('MM/DD/YYYY');
				if(initialDate && date) $input.val(initialDate);
			});
		}
	}
}

/* ----------  Mini Repeater  ---------- */

const MiniRepeater = {
	init: (data, type = null) => {
		data = !_.isEmpty(data) ? JSON.parse(data) : {};
		if(!_.isEmpty(data)) MiniRepeater.addData(data, type);

		Repeater.init(type);
	},

	getData: (type = null) => {
		const data = [];
		const { area } = Repeater.dom(type);
		const items = area.querySelectorAll('[data-repeater-item]');

		if(items.length) {
			_.map(items, item => {
				const obj = {};
				const inputKey = item.querySelector(`[name="key"]`);
				const inputValue = item.querySelector(`[name="value"]`);

				if(inputKey) obj.key = inputKey.value;
				if(inputValue) {
					obj.value = inputValue.value;
					const mce = tinymce.get(inputValue.getAttribute('id'));

					if(mce) {
						obj.value = mce.getContent();
					}
				}

				if(obj.key) data.push(obj);
			});
		}

		return data;
	},

	addData: (data, type) => {
		_.map(data, row => {
			const $el = Repeater.addItem(row, type);

			$('[name="key"]', $el).val(row.key);
			$('[name="value"]', $el).val(row.value);
		});
	},
}

/* ----------  Repeater  ---------- */

const Repeater = {
	init: (type = null) => {
		const { area, atnAdd } = Repeater.dom(type);

		$(atnAdd).off('click.add').on('click.add', e => {
			e.preventDefault();
			Repeater.addItem({}, type || area.getAttribute('data-repeater-area'));
		});

		$(area).off('click.remove').on('click.remove', '[data-repeater-delete]', e => {
			e.preventDefault();

			const $btn = $(e.currentTarget);
			const $row = $btn.closest('[data-repeater-item]');

			Repeater.removeItem($row, type);
		});
	},

	addItem: (data, type) => {
		const { el, area } = Repeater.dom(type);
		const $el = $(el).clone();

		$(area).append($el);
		$(area).trigger('repeater.onAddItem', [$el[0], data]);

		return $el;
	},

	removeItem: (row, type) => {
		const { area } = Repeater.dom(type);

		$(row).detach();
		$(area).trigger('repeater.onRemoveItem', [row]);
	},

	dom: type => ({
		el: type ? document.querySelector(`[data-repeater-dom="${ type }"] [data-repeater-item]`) : document.querySelector(`[data-repeater-dom] [data-repeater-item]`),
		area: type ? document.querySelector(`[data-repeater-area="${ type }"]`) : document.querySelector(`[data-repeater-area]`),
		atnAdd: type ? document.querySelector(`[data-repeater-add="${ type }"]`) : document.querySelector(`[data-repeater-add]`),
	})
}

/* ----------  Perfect Scrollbar  ---------- */

const Scrollbar = {
	init: () => {
		if(typeof PerfectScrollbar === undefined) return;

		const containers = document.querySelectorAll('[data-ps]');

		_.map(containers, container => {
			const ps = new PerfectScrollbar(container);
			ps.update();
		});
	}
}

/* ----------  Modals  ---------- */

const TWBS_Modal = {
	defaults: () => {
		$(document).on('show.bs.modal.defaults', '.modal',  e => {
			const zIndex = 1040 + (10 * $('.modal:visible').length);
			$(e.currentTarget).css('z-index', zIndex);

			setTimeout(() => {
				$('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
			}, 0);
		});

		$(document).on('hidden.bs.modal.defaults', '.modal',  e => {
			const isModal = $('.modal:visible').length;

			if(isModal) {
				$('body').addClass('modal-open');
			} else {
				$('body').removeClass('modal-open');
			}
		});
	}
}

/* ----------  Popups  ---------- */

const Popups = {
	init: () => {
		const $atnToggle = $('[data-toggle="popup"]');
		const $atnClose = $('[data-dismiss="popup"]');
		const $popups = $('[data-popup]');

		$atnToggle.off('click.togglePopup').on('click.togglePopup', e => {
			e.preventDefault();

			const $atn = $(e.currentTarget);
			const $popup = $($atn.data('target'));

			if(!$popup.length) return;

			if($popup.is(':visible')) {
				$popup.fadeOut('fast', () => {
					$popup.removeClass('popup-open');
				});
			} else {
				$popup.fadeIn('fast', () => {
					$popup.addClass('popup-open');
					$popup.trigger('popup-shown');
				});
			}
		});

		$atnClose.off('click.closePopup').on('click.closePopup', e => {
			e.preventDefault();

			const $atn = $(e.currentTarget);
			const $popup = $atn.closest('[data-popup]');

			console.log($popup);

			if(!$popup.length) return;

			$popup.fadeOut('fast', () => {
				$popup.removeClass('popup-open');
			});
		});

		$popups.off('open').on('open', e => {
			e.preventDefault();

			const $popup = $(e.currentTarget);
			$popup.fadeIn('fast', () => {
				$popup.addClass('popup-open');
				$popup.trigger('popup-shown');
			});
		});

		$popups.off('close').on('close', e => {
			e.preventDefault();

			const $popup = $(e.currentTarget);
			$popup.fadeOut('fast', () => {
				$popup.removeClass('popup-open');
			});
		});
	}
}
