/* ----------  App Progress  ---------- */

const Progress = {
	show: cnt => {
		const $cnt = $(cnt);
		const progress = `<div class="ph-request-loader phLoader">
			<span class="ph-loader-inner">
				<span class="ph-loader"></span>
			</span>
		</div>`;

		if(!$('.phLoader', $cnt).length) {
			$cnt.css('position', 'relative').addClass('ph-inprogress').append(progress);
		}
	},

	hide: cnt => {
		const $cnt = cnt ? $(cnt) : $('body');

		$('.phLoader', $cnt).detach();

		$cnt.css('position', '').removeClass('ph-inprogress');
	}
}
