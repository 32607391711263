/* ----------  Alerts  ---------- */

const Alert = {
	getTexts: textArr => {
		let text = '';

		_.map(textArr, t => {
			text += `<li class="m-b-5">${ t }</li>`;
		});

		return text;
	},

	show: (title, text, type, opts, callback) => {
		let list = '';

		if((typeof text === 'object') || (typeof text === 'array')) {
			list = `<ul class="list-unstyled m-0">${ Alert.getTexts(text) }</ul>`;
		}

		Swal.fire({
			html: list || text,
			title,

			type: type || 'info',
			allowEscapeKey: true,
			allowOutsideClick: true,
			confirmButtonText: 'Ok',

			...opts
		}).then(result => {
			if(callback) callback(result);
		});

		if(opts && opts.onWaited) {
			setTimeout(() => {
				opts.onWaited();
			}, 2000);
		}
	},

	info: (title, text, opts, callback) => Alert.show(title, text, 'info', opts, callback),

	error: (title, text, opts, callback) => Alert.show(title, text, 'error', opts, callback),

	success: (title, text, opts, callback) => Alert.show(title, text, 'success', opts, callback),

	warning: (title, text, opts, callback) => Alert.show(title, text, 'warning', opts, callback),

	notice: (text, title, closeable = true) => {
		Swal.fire({
			text,
			title,

			showCancelButton: false,
			showConfirmButton: false,
			allowEscapeKey: closeable,
			allowOutsideClick: closeable,
		});
	},

	confirm: (title, text, callback) => {
		Alert.show(title, text, 'question', {
			showCancelButton: true,
			confirmButtonText: 'Confirm'
		}, callback);
	}
}
