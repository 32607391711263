/*----------  Global Variables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/

$(document).ready(() => {
	wWidth = $(window).width();
	wHeight = $(window).height();

	// Validate Defaults
	Validate.defaults();

	// Components
	AirDatepicker.init();

	// Common
	FullHeight.init();

	// Header
	Header.handleNavbar();

	// Footer
	Footer.init();

	// Modal
	Modal.init();

	// Modal
	UploadMenu.init();

	console.log('Ready!');
});

/*----------  Window Events  ----------*/

$(window).on('load resize', () => {
	wWidth = $(window).width();
	wHeight = $(window).height();
});

/* ----------  Modal  ---------- */

const Modal = {
	init: () => {
		const $atn = $('[data-trigger="modal"]');

		$atn.off('click.modal').on('click.modal', e => {
			e.preventDefault();
			e.stopPropagation();

			const $this = $(e.currentTarget);
			const modal = $this.data('target');
			const $modal = $(modal);

			if ($modal.length) {
				$modal.modal('show');
			}
		});
	}
}
