/* ----------  Full Height  ---------- */

const FullHeight = {
	init: () => {
		const parent = document.querySelector('[data-fullheight-parent]');

		if(parent) {
			const height = `${ parent.clientHeight }px`;
			const elements = parent.querySelectorAll('[data-fullheight]');

			_.map(elements, el => {
				const attr = el.getAttribute('data-fullheight');

				switch(attr) {
					case 'min': el.style.minHeight = height; break;
					case 'max': el.style.maxHeight = height; break;
					default: el.style.height = height; break;
				}
			});
		}
	}
}
