/* ----------  Form  ---------- */

let $vForm,
	$vButton;

const vForm = {
	init: (form, names, values) => {
		$vForm = $(form);
		$vButton = $('[data-vbutton]', $vForm);

		if($vForm.length) {
			vForm.validate(names, values);
		}
	},

	validate: (names = [], values = {}) => {
		const valids = _.map(names, name => _.isEmpty(values[name]) ? 'invalid' : 'valid');
		const isValid = _.filter(valids, item => item === 'valid').length === names.length;

		vForm.toggleButton(isValid);
	},

	toggleButton: isValid => {
		if(isValid) {
			$vButton.removeClass('disabled');
		} else {
			$vButton.addClass('disabled');
		}
	}
}
