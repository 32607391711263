/* ----------  Card Progress  ---------- */

const CardProgress = {
	show: card => {
		const $card = $(card);
		const progress = `<div class="card-progress cardProgress">
			<div class="progress-border">
				<div class="spinner-border text-dark" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>`;

		$card.append(progress);
		$card.addClass('card-inprogress');
	},

	hide: card => {
		const $card = card ? $(card) : $('body');

		$('.cardProgress', $card).detach();
		$card.removeClass('card-inprogress');
	}
}
