/*----------  Status  ----------*/

const Status = {
	text: code => {
		switch(parseInt(code)) {
			case 10: return 'Active';
			case 20: return 'Inactive';
			case 30: return 'Pending';
			case 40: return 'Rejected';
		}
	},

	class: code => {
		switch(parseInt(code)) {
			case 10: return 'success';
			case 20: return 'danger';
			case 30: return 'warning';
			case 40: return 'danger';
		}
	}
}
