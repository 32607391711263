/* ----------  jQuery Validation  ---------- */

const Validate = {
	defaults: () => {
		jQuery.validator.setDefaults({
			ignore: [],
			errorElement: 'label',
			validClass: 'jqv-valid',
			errorClass: 'jqv-invalid',

			errorPlacement: (error, element) => {
				const $error = $(error);
				const $element = $(element);

				const $parent = $element.closest('[data-jqv-group]');

				$element.addClass('is-invalid');
				$error.addClass('jqvLabel jqv-error');

				$parent.addClass('jqv-has-errors');
				$parent.append($error);

				console.log('errorPlacement', $element);
			},

			success: label => {
				const $label = $(label);

				const $parent = $label.closest('[data-jqv-group]');
				const $input = $('[data-jqv-input]', $parent);

				$parent.removeClass('jqv-has-errors');
				$input.removeClass('is-invalid').addClass('is-valid');

				$label.detach();

				console.log('success', $input);
			},
		});
	},

	init: (form, configs) => $(form).validate(configs),

	addRules: (input, opts) => {
		$(input).rules('add', opts);
	},

	removeRules: (input, rules = '') => {
		const $input = $(input);
		$input.rules('remove');

		const $parent = $input.closest('[data-jqv-group]');

		$('.jqvLabel', $parent).detach();

		$parent.removeClass('jqv-has-errors');
		$input.removeClass('is-invalid is-valid jqv-valid jqv-invalid');
	},

	resetForm: (form, reset = true) => {
		const $form = $(form);

		const $groups = $('[data-jqv-group]', $form);
		const $inputs = $('[data-jqv-input]', $form);
		const $labels = $('.jqvLabel', $form);

		$groups.removeClass('jqv-has-errors');
		$inputs.removeClass('is-invalid is-valid');

		$labels.detach();

		if(reset) form.reset();
	}
}
