/* ----------  Uploader JS  ---------- */

const Uploader = {
	init: (id, group, attachmentId = null, label = null) => {
		FilePond.registerPlugin(FilePondPluginImagePreview);

		const file = document.querySelector(`#file_${ id }`);
		const txt = document.querySelector(`#fileTxt_${ id }`);
		const name = document.querySelector(`#label_${ id }`);
		const card = txt.closest('.card');

		label = label || '';

		const pond = FilePond.create(file, {
			maxFiles: 1,
			autoProcess: true,
			instantUpload: true,
			labelIdle: label || 'Drag & Drop your files or <span class="filepond--label-action"> Browse </span> or <span class="filepond--label-action"> Take Photo </span>',

			server: {
				revert: null,
				load: ATTACHMENT.load,

				process: {
					url: ATTACHMENT.upload,
					method: 'POST',

					onload: data => {
						const response = JSON.parse(data);
						if(response.result) {
							txt.value = response.result.attachmentId;
							if(name) {
								name.innerHTML = response.result.name;
							}
						}

						console.log(txt.value);
					},

					onerror: data => {
						console.log(response);
					},

					ondata: formData => {
						formData.append('group', group);
						return formData;
					}
				},
			},

			onaddfilestart: () => {
				// if(card) CardProgress.show(card);
			},

			onprocessfile: () => {
				// if(card) CardProgress.hide(card);
			},

			onremovefile: (error, file) => {
				txt.value = '';
			}
		});

		if(attachmentId) {
			pond.addFile(attachmentId, {
				type: 'local'
			}).then(() => {
				txt.value = attachmentId;
				CardProgress.hide(card);
			});
		}
	}
}
