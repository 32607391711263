const Footer = {
	init: () => {
		Footer.handleMenu();
		Footer.handleHealthCheckDrawer();
	},

	handleMenu: () => {
		const $menu = $('#footerMenu');
		const $backdrop = $('#footerMenuBackdrop');

		if(!$menu.length) return false;

		const menuHammer = new Hammer($menu[0]);
		const backdropHammer = new Hammer($backdrop[0]);

		menuHammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
		menuHammer.on('swipe tap', e => {
			if(e.type === 'swipe') {
				if(e.direction === 8) {
					$menu.addClass('open');
				} else {
					$menu.removeClass('open');
				}
			} else if(e.type === 'tap') {
				$menu.addClass('open');
			}
		});

		backdropHammer.get('swipe').set({ direction: Hammer.DIRECTION_DOWN });
		backdropHammer.on('swipe tap', e => {
			if($menu.hasClass('open')) {
				$menu.removeClass('open');
			}
		});

		return true;
	},

	handleHealthCheckDrawer: () => {
		const $menu = $('#healthCheckMenu');

		if(!$menu.length) return false;

		const menuHammer = new Hammer($menu[0]);

		menuHammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
		menuHammer.on('swipe tap', e => {
			if(e.type === 'swipe') {
				if(e.direction === 8) {
					$menu.addClass('open');
				} else {
					$menu.removeClass('open');
				}
			} else if(e.type === 'tap') {
				$menu.addClass('open');
			}
		});

		$menu.on('open', e => {
			$menu.addClass('open');
		});

		$menu.on('close', e => {
			$menu.removeClass('open');
		});

		return true;
	},
}
