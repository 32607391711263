/* ----------  Search  ---------- */

const Search = {
	getRequestData: data => ({
		page: 0,
		count: 1,
		startIndex: -1,
		term: null,
		sort: {
			column: 'autoId',
			dir: 'desc'
		},
		...data,
	}),

	getPlaceholder: (text = 'Loading...') => `<li class="text-center font-italic"><small>${ text }</small></li>`,

	request: (url, requestData, opts) => {
		const data = Search.getRequestData(requestData);
		const options = {
			$cnt: $('[data-search]'),
			...opts
		}

		Progress.show(options.$cnt);

		const $list = $('[data-list]', options.$cnt);
		$list.empty();
		$list.append(Search.getPlaceholder());

		PostRequest(url, data, (status, response) => {
			Progress.hide();
			if(options.onSuccess) options.onSuccess(status, response);
		}, (status, response) => {
			$list.empty();
			$list.append(Search.getPlaceholder('No results found!'));

			Progress.hide();
			Alert.error('Oops!', response.result ? response.result.errors : response.message);
			if(options.onFail) options.onFail(status, response);
		});
	}
}
