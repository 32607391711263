/* ----------  Header  ---------- */

const Header = {
	handleNavbar: () => {
		const $navbar = $('.appNavbar');
		const $title = $('.navbarTitle', $navbar);
		const $actions = $('.navbarActions', $navbar);

		let padding = 0;
		_.map($actions, action => {
			const actionWidth = action.clientWidth;
			if(padding <= actionWidth) {
				padding = actionWidth;
			}
		});

		$title.css({
			paddingLeft: padding,
			paddingRight: padding,
		});
	}
}
