/*----------  ResultType  ----------*/

const ResultType = {
	text: code => {
		switch(code) {
			case "two_line_cassette": return '2 Line Cassette';
			case "igm_igg_cassette": return 'IgG/IgM Cassette';
			case "molecular_naat": return 'Molecular/NAAT';
			case "positive_negative": return 'Positive/Negative';
			case "yes_no": return 'Yes/No';
			case "antibody": return 'SARS-CoV-2 ANTIBODIES DETECTED / SARS-CoV-2 ANTIBODIES NOT DETECTED';
			case "influenza": return 'Influenza';
			default : return 'N/A';
		}
	},

	class: code => {
		switch(code) {
			case "two_line_cassette": return 'success';
			case "igm_igg_cassette": return 'success';
			case "molecular_naat": return 'success';
			case "positive_negative": return 'success';
			case "yes_no": return 'success';
			case "antibody": return 'success';
			case "influenza": return 'success';
			default : return 'success';
		}
	}
}
